import styles from "./style.module.scss";
import OneElement from "./oneElement";
import DownArrow from "./icons/DownArrow";

import { ChangeEvent, Dispatch, useEffect, useState } from "react";
import { TranslationsObject } from "@genericTypes/sharedTypes";
import { useQuery } from "@tanstack/react-query";
import { translate } from "@helpers/translationObjectFormatter";
import AnimateHeight from "../animateHeight";
interface OptionType {
    id: number;
    label: string;
    slug: string;
}
export default function CustomMultiSelect({
    options,
    placeHolder,
    onAccept,
    disabled,
    acceptButtonName,
    onCheckedOn,
    onCheckedOff,
    selectedCategory,
    className,
    multiSelectSearchValue,
    setMultiSelectSearchValue,
}: {
    options: OptionType[];
    placeHolder: string;
    onAccept: CallableFunction;
    disabled: boolean;
    acceptButtonName: string;
    onCheckedOn: CallableFunction;
    onCheckedOff: CallableFunction;
    selectedCategory: number | undefined;
    className?: string;
    multiSelectSearchValue: string;
    setMultiSelectSearchValue: Dispatch<React.SetStateAction<string>>;
}) {
    const [showSelect, setShowSelect] = useState(false);
    const [list, setList] = useState([] as number[]);
    const [notFound, setNotFound] = useState(false);
    const [defaultOptions, setDefaultOptions] = useState<OptionType[]>(options);
    const updateList = (value: number[]) => {
        setList(value);
    };

    const { data: translations } = useQuery<TranslationsObject>([
        "translations",
    ]);

    useEffect(() => {
        setDefaultOptions(options);
    }, [options]);

    useEffect(() => {
        setList([]);
    }, [selectedCategory]);

    const handleOutsideClick = (e: MouseEvent) => {
        //@ts-ignore
        const { className } = e.target;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        if (
            typeof className !== "string" ||
            !className ||
            !className?.includes("customSelect")
        ) {
            setShowSelect(false);
        }
    };

    useEffect(() => {
        document.body.addEventListener("click", handleOutsideClick);

        return () => {
            document.body.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    const sortOptionsAlphabetically = (a: OptionType, b: OptionType) => {
        return a.label.trim()?.localeCompare(b.label.trim());
    };
    defaultOptions?.sort(sortOptionsAlphabetically);

    const filterOptions = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        setMultiSelectSearchValue(value);
    };

    useEffect(() => {
        if (
            multiSelectSearchValue.length === 0 &&
            multiSelectSearchValue === ""
        ) {
            setDefaultOptions(options);
        } else {
            const filteredDefaultOptions = options.filter((item) =>
                item.label
                    .trim()
                    .toLowerCase()
                    .includes(multiSelectSearchValue.toLowerCase()),
            );
            if (filteredDefaultOptions.length === 0) {
                setNotFound(true);
            } else {
                setNotFound(false);
                setDefaultOptions(filteredDefaultOptions);
            }
        }
    }, [multiSelectSearchValue]);

    const firstSelectedVendor = defaultOptions?.find(
        (item) => item?.id === list[0],
    );

    return (
        <div
            className={`${
                className ? className : "w-[250px] customSelect"
            } border rounded-lg absolute  max-h-[250px] overflow-y-auto  bg-white customSelect`}
            tabIndex={0}
        >
            <div
                className={`${
                    showSelect ? styles["borderRadiusStyle"] : "customSelect"
                } py-3 px-5 sticky top-0 bg-white z-10 customSelect ${
                    styles["borderStyle"]
                } flex items-baseline justify-between ${
                    disabled ? styles["borderStyleDisabled"] : "customSelect"
                }`}
                onClick={() => {
                    if (!disabled) {
                        setShowSelect(true);
                    }
                }}
            >
                {showSelect ? (
                    <input
                        className={`${styles.inputStyle} customSelect z-[100000]`}
                        autoFocus
                        autoComplete={"off"}
                        value={multiSelectSearchValue}
                        placeholder={translate(
                            translations,
                            "several_general_search",
                        )}
                        id="optionsSearchInput"
                        onChange={(e) => filterOptions(e)}
                    ></input>
                ) : (
                    <>
                        <span
                            className={` max-h-[30px] rtl:max-h-[29px] customSelect  overflow-hidden
                    `}
                        >
                            {firstSelectedVendor
                                ? `${firstSelectedVendor.label}  ${
                                      list?.length > 1
                                          ? `and ${
                                                list.length - 1
                                            } other Vendors...`
                                          : ""
                                  } `
                                : placeHolder}
                        </span>
                        <span className={styles["iconPointer"]}>
                            <DownArrow />
                        </span>
                    </>
                )}
            </div>
            <AnimateHeight height={showSelect ? "auto" : 0} duration={200}>
                <div className="customSelect">
                    {notFound ? (
                        <div className={`pl-6  py-2 customSelect`}>
                            No results
                        </div>
                    ) : (
                        defaultOptions?.map((item) => (
                            <>
                                <OneElement
                                    item={item}
                                    key={item.id}
                                    onCheckedOff={onCheckedOff}
                                    onCheckedOn={onCheckedOn}
                                    list={list}
                                    updateList={updateList}
                                />
                            </>
                        ))
                    )}
                    <div className="flex customSelect text-center border-t sticky bottom-0 bg-white">
                        <div
                            className={` ${
                                list.length > 1
                                    ? styles["compareStyle"]
                                    : styles["compareStyleDisabled"]
                            } w-full cursor-pointer p-1 py-[9px] customSelect `}
                            onClick={() => {
                                if (list.length > 1) {
                                    setShowSelect(false);
                                    onAccept(list);
                                }
                            }}
                        >
                            {acceptButtonName ?? "Ok"}
                        </div>
                    </div>
                </div>
            </AnimateHeight>
        </div>
    );
}
