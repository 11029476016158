import React, { ReactElement, useState } from "react";
import Text from "@components/shared/text";
import styles from "./style.module.scss";
import Step from "./step";
import { GQLCategories } from "@gql/types/gql";
import OrangeDotsSvg from "@components/shared/orangeDotsSvg/OrangeDotsSvg";
import { useQuery } from "@tanstack/react-query";
import { TranslationsObject } from "@genericTypes/sharedTypes";
import { translate } from "@helpers/translationObjectFormatter";

export default function CompareHome(): ReactElement {
    const [selectedCategory, setSelectedCategory] =
        useState<GQLCategories | null>(null);
    const { data: translations } = useQuery<TranslationsObject>([
        "translations",
    ]);
    const steps = [
        {
            id: 1,
            stepTitle: `${translate(
                translations,
                "several_homePage_compare1_title",
            )}`,
            name: "selectedCategory",
            imgPath: "step1_v2.png",
            description: `${translate(
                translations,
                "several_homePage_compare1_description",
            )}`,
            input: {
                type: "select",
                label: `${translate(
                    translations,
                    "several_general_category_select_placeholder",
                )}`,
                multiSelect: false,
            },
        },
        {
            id: 2,
            stepTitle: `${translate(
                translations,
                "several_homePage_compare2_title",
            )}`,
            imgPath: "step2_v2.png",
            name: "selectedVendor",
            description: `${translate(
                translations,
                "several_homePage_compare2_description",
            )}`,
            input: {
                type: "multiSelect",
                label: `${translate(
                    translations,
                    "several_general_vendor_select_placeholder",
                )}`,
                multiSelect: false,
            },
        },
        {
            id: 3,
            stepTitle: `${translate(
                translations,
                "several_homePage_compare3_title",
            )}`,
            name: "compare",
            imgPath: "step3_v2.png",
            description: `${translate(
                translations,
                "several_homePage_compare3_description",
            )}`,
            input: {
                type: "button",
                label: `${translate(
                    translations,
                    "several_general_compareButton",
                )}`,
            },
        },
    ];

    return (
        <div className="pb-14 pt-10 md:pt-16 lg1048:pb-32">
            <div className="text-center max-w-3xl mx-auto pb-32">
                <Text className="mb-5" type="title">
                    {translate(translations, "several_homePage_mainTitle")}
                </Text>
                <Text className="text-base mb-10 lg1048:text-xl">
                    {translate(translations, "several_homePage_subTitle")}
                </Text>
            </div>
            <div
                className={`${styles.steps} flex flex-col lg1048:flex-row justify-between items-stretch  px-5 relative`}
            >
                <div
                    className={`${styles.orangeDotWrapper} top-[35%] left: sm:top-[52%] !-z-10`}
                >
                    <OrangeDotsSvg />
                </div>
                {steps?.map((step) => (
                    <Step
                        key={step.id}
                        step={step}
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                    />
                ))}
            </div>
        </div>
    );
}
