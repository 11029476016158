/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, {
    Dispatch,
    ReactElement,
    SetStateAction,
    useEffect,
    useState,
} from "react";
import Image from "next/legacy/image";
import Text from "@components/shared/text";
import styles from "./style.module.scss";
import Button from "@components/shared/form/button";
import { GQLCategories, GQLOffers } from "@gql/types/gql";

import CustomSelect from "@components/shared/customSelect";

import CustomMultiSelect from "@components/shared/customMultiSelect";
import { StepInterface, TranslationsObject } from "@genericTypes/sharedTypes";
import { useAtom } from "jotai";
import { stepsActive, compareList, showMultiList } from "@store/homePageStore";
import { useRouter } from "next/router";
import { useQuery } from "@tanstack/react-query";
import { translate } from "@helpers/translationObjectFormatter";
import { removeWordFromString } from "@helpers/removeWordFromString";
import { sortArray } from "@helpers/sortArrayOfStrings";
import useWindowSize from "@hooks/useWindowSize";

export default function Step({
    step,
    selectedCategory,
    setSelectedCategory,
}: {
    step: StepInterface;
    selectedCategory: GQLCategories | null;
    setSelectedCategory: Dispatch<SetStateAction<GQLCategories | null>>;
}): ReactElement {
    const window = useWindowSize();

    const { data } = useQuery<GQLCategories[], Error>({
        queryKey: ["categoriesAndOfferDropdown"],
    });
    const { data: translations } = useQuery<TranslationsObject>([
        "translations",
    ]);
    const [stepActiveList, setActive] = useAtom(stepsActive);
    const [, setShowMultiList] = useAtom(showMultiList);

    const [list, setList] = useAtom(compareList);
    const allCategories = data
        ? data?.filter((el) => (el?.offers?.length ?? 0) > 1)
        : [];
    const [activeOffers, setActiveOffers] = useState<GQLOffers[]>([]);
    const [multiSelectSearchValue, setMultiSelectSearchValue] = useState("");

    useEffect(() => {
        const currentOffers = allCategories
            .find((category) => category.slug === selectedCategory?.slug)
            ?.offers?.map((item) => {
                return {
                    ...item,
                    slug: removeWordFromString(item?.slug as string),
                };
            }) as GQLOffers[];
        setActiveOffers(currentOffers);
    }, [selectedCategory]);

    const query = useRouter();

    let inputType = <></>;
    if (step.input.type === "button") {
        inputType = (
            <Button
                type="submit"
                disabled={!stepActiveList[2]}
                style={{ width: "100%", height: "50px" }}
                onClick={() => {
                    const orderedSlugs = sortArray(list);
                    const url = orderedSlugs.join("-vs-");
                    void query.push(
                        `${selectedCategory?.slug as string}/comparison/${url}`,
                    );
                    setActive([true, false, false]);
                    setShowMultiList(false);
                }}
                className={`text-base font-bold w-full relative top-7 lg:top-0 ${styles["buttonStyle"]}`}
            >
                {step.input.label}
            </Button>
        );
    }
    if (step.input.type === "select") {
        inputType = (
            <CustomSelect
                options={allCategories.map((el) => ({
                    id: el.id as number,
                    label: el.label ?? el.name ?? "",
                    slug: el.slug ?? "",
                }))}
                className="border-[2px] w-full md:w-[16rem] border-[#1693F0]"
                placeHolder={step.input.label}
                onChange={(slug: GQLCategories) => {
                    setSelectedCategory(slug);

                    setList([]);

                    setActive([true, true, false]);
                }}
            />
        );
    }
    if (step.input.type === "multiSelect") {
        inputType = (
            <>
                <div style={{ minHeight: "56px" }}>
                    <CustomMultiSelect
                        multiSelectSearchValue={multiSelectSearchValue}
                        setMultiSelectSearchValue={setMultiSelectSearchValue}
                        options={activeOffers?.map((el) => ({
                            id: el.id as number,
                            label: el.name ?? el.title ?? "",
                            slug: el.slug ?? "",
                        }))}
                        placeHolder={step.input.label}
                        acceptButtonName={`${translate(
                            translations,
                            "several_general_compareButton",
                        )}`}
                        onAccept={() => {
                            setActive([true, false, false]);
                            const orderedSlugs = sortArray(list);
                            const url = orderedSlugs.join("-vs-");
                            void query.push(
                                `${
                                    selectedCategory?.slug as string
                                }/comparison/${url}`,
                            );
                        }}
                        onCheckedOn={(listOfId: number[]) => {
                            setMultiSelectSearchValue("");
                            const listOfSlug = activeOffers
                                .filter((offer) =>
                                    listOfId.includes(offer.id as number),
                                )
                                .map((offer) => offer.slug);

                            setList(listOfSlug as string[]);
                            if (listOfId.length >= 1) {
                                setActive([true, true, true]);
                            } else {
                                setActive([true, true, false]);
                            }
                        }}
                        onCheckedOff={(listOfId: number[]) => {
                            const listOfSlug = activeOffers
                                .filter((offer) =>
                                    listOfId.includes(offer.id as number),
                                )
                                .map((offer) => offer.slug);

                            setList(listOfSlug as string[]);
                            if (listOfId.length >= 1) {
                                setActive([true, true, true]);
                            } else {
                                setActive([true, true, false]);
                            }
                        }}
                        disabled={!stepActiveList[1]}
                        selectedCategory={selectedCategory?.id as number}
                        className="customSelect w-full md:w-[16rem] border-[2px] border-[#1693F0]"
                    />
                </div>
                <div style={{ fontSize: "12px", color: "#999" }}>
                    {translate(translations, "several_homePage_compare2_note")}
                </div>
            </>
        );
    }

    return (
        <div
            className={`${
                styles.step
            }   pt-4  lg1048:pt-20 px-4 sm:px-10 rounded-lg mx-auto lg1048:mx-0 mb-2.5 lg1048:mb-0 ${
                step.id === 1
                    ? "pb-20 lg1048:pb-4"
                    : step.id === 2
                    ? "pb-7 md:pb-20 lg1048:pb-4"
                    : "hidden md:block pb-14 md:pb-20 lg1048:pb-4 "
            } ${step.id === 1 ? "z-50" : ""} ${step.id === 2 ? "z-40" : ""}`}
        >
            <div
                className={`${styles.stepImage} ${
                    step.id === 1 ? "block" : "hidden lg1048:block"
                } `}
            >
                {step.imgPath ? (
                    <Image
                        src={`/${step.imgPath}`}
                        alt="step1"
                        width="150"
                        height="249"
                        priority={
                            window.width < 1024
                                ? step.id === 1
                                    ? true
                                    : false
                                : true
                        }
                    />
                ) : null}
            </div>
            <div className={`${styles.stepContent} `}>
                <Text className="font-extrabold ">{step.stepTitle}</Text>
                <Text
                    className={`text-sm  pb-2 lg1048:pb-0  mt-2 ${
                        step.id === 1
                            ? "block"
                            : stepActiveList[step.id - 1]
                            ? "block"
                            : "hidden lg1048:block"
                    }`}
                >
                    {step.description}
                </Text>
            </div>

            <div
                className={`relative ${
                    step.id === 1
                        ? "block"
                        : stepActiveList[step.id - 1]
                        ? "block"
                        : "hidden lg1048:block"
                }`}
            >
                {inputType}
            </div>
        </div>
    );
}
