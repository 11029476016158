import Link from "@components/shared/linkNoPrefetch";
import React from "react";
import Image from "next/image";

const Vendor = ({
    imgPath,
    slug,
    url,
}: {
    imgPath: string;
    slug: string;
    categorySlug?: string;
    url: string;
}) => {
    return (
        <Link href={url}>
            <div className="w-[8rem] p-0 m-0 h-[7rem] sm:w-[20rem] sm:h-[9rem] flex items-center justify-center bg-[white] rounded-[8px] hover:border-[#F9BB21] hover:border-[3px] cursor-pointer transition">
                <div className="w-full h-full max-h-[95px] max-w-[120px]  relative mx-auto flex items-center justify-center">
                    <Image
                        src={imgPath}
                        alt={slug}
                        style={{ display: "inline-block" }}
                        className="max-h-[95px] max-w-[120px]"
                        width={"100"}
                        height={"100"}
                    />
                </div>
            </div>
        </Link>
    );
};

export default Vendor;
