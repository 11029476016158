import React, { ReactElement } from "react";
import Image from "next/legacy/image";
import Text from "@components/shared/text";
import Link from "@components/shared/linkNoPrefetch";
import styles from "./style.module.scss";
import { GQLPosts, GQLUrls } from "@gql/types/gql";
import blogImgPlaceholder from "@public/placeHolder400x255.png";
import {
    ICategoryArticlesPageProps,
    TranslationsObject,
} from "@genericTypes/sharedTypes";
import { useQuery } from "@tanstack/react-query";
import { translate } from "@helpers/translationObjectFormatter";
import { resolveURL, titleDecoder } from "@lib/sharedUtils";
import { getVisitorInfo } from "@lib/sharedUtils";
import { VisitorInfo } from "@genericTypes/sharedTypes";
export default function FeaturedBlogPosts({
    data,
}: {
    data: GQLPosts;
}): ReactElement {
    const { data: props } = useQuery<ICategoryArticlesPageProps, Error>([
        "articlesPageProps",
    ]);
    const { category } = props ?? {};

    const { data: translations } = useQuery<TranslationsObject>([
        "translations",
    ]);

    const visitorInfo: VisitorInfo = getVisitorInfo();

    return (
        <div
            className={`${styles.postWrapper} black sm:flex  relative`}
            itemScope
            itemType="https://schema.org/Blog"
        >
            <Link
                href={`${resolveURL(data?.url as GQLUrls)}`}
                className={`${styles.post} flex flex-row items-center lg1048:flex-col rounded-lg m-3.5`}
            >
                <div className={`w-[10rem] sm:w-full `}>
                    {data?.image?.fullPath ? (
                        <div
                            className={`relative w-[10rem] sm:w-full ltr:rounded-l-lg rtl:rounded-r-lg sm:rounded-t-lg sm:rounded-b-[0]   overflow-hidden`}
                        >
                            <img
                                src={blogImgPlaceholder.src}
                                alt={data?.title ?? ""}
                            />
                            <Image
                                src={`${data?.image?.fullPath}`}
                                alt={data?.title ?? ""}
                                layout="fill"
                                objectFit="cover"
                                itemProp="image"
                                width={404}
                                height={255}
                                sizes="(min-width: 1024px)  100vw, 33vw"
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                <div
                    className="lg1048:text-center ps-2 lg1048:p-5 text-sm lg1048:text-xl mt-1 lg:mt-6"
                    itemProp="name"
                    title={data?.title ?? ""}
                >
                    <Text
                        className={` font-normal lg1048:font-bold  mb-2 lg1048:mb-4 ${styles.postTitleBlock}`}
                    >
                        {titleDecoder(data?.title as string, {
                            region: visitorInfo?.region ?? "",
                            category: category?.label ?? "",
                        })}
                    </Text>
                    <div
                        className={`${styles.more}  inline-block text-xs lg1048:text-base`}
                    >
                        {translate(
                            translations,
                            "several_general_readMoreButton",
                        )}
                    </div>
                </div>
            </Link>
        </div>
    );
}
