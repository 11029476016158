import React, { ReactElement, useEffect } from "react";
import CompareHome from "@components/compare-homepage";
import DefaultLayout from "@layout/default";
import type { NextPageWithLayout } from "./_app";
import GenericForm from "@components/genericForm";
import TopVendors from "@components/topVenders";
import SupportedLanguages from "@components/supportedLanguages";
import FeaturedBlogPosts from "@components/featuredBlogPosts";
import MetaTags from "@components/shared/metaTags";
import { GQLPosts, GQLDomains } from "@gql/types/gql";
import { useQuery } from "@tanstack/react-query";
import initialCall from "@helpers/initial-call";
import CategoriesTab from "@components/shared/categoriesTab";
import {
    getDescriptionForMetaTags,
    getKeyWordsForMetaTags,
} from "@lib/sharedUtils";
import CroppedOrangeDots from "@components/shared/cropedOrangeDots";
import { GetStaticPropsContext } from "next";
import { activePageAtom } from "@store/generalStore";
import { useSetAtom } from "jotai";
import { ICustomDomain, TranslationsObject } from "@genericTypes/sharedTypes";
import { translate } from "@helpers/translationObjectFormatter";

const Page: NextPageWithLayout = function Pg() {
    const { data: domainInfo } = useQuery<ICustomDomain, Error>({
        queryKey: ["domain"],
    });
    const { data: posts } = useQuery<GQLPosts[], Error>([
        "featuredPostsHomePage",
    ]);
    const { data: translations } = useQuery<TranslationsObject>([
        "translations",
    ]);
    const setActivePage = useSetAtom(activePageAtom);
    useEffect(() => {
        setActivePage("homepage");
    }, []);

    return (
        <>
            <MetaTags
                title={`${
                    domainInfo?.domain?.description ??
                    `${translate(
                        translations,
                        "several_searchPage-meta-title-2",
                    )} | Several.com`
                }`}
                description={
                    getDescriptionForMetaTags(
                        domainInfo?.domain as GQLDomains,
                        translate(
                            translations,
                            "several_general_meta_description",
                        ),
                    ) as string
                }
                keyWords={
                    getKeyWordsForMetaTags(
                        domainInfo?.domain as GQLDomains,
                    ) as string
                }
            />
            <h1 className="hidden">Home Page</h1>
            <div className="max-w-7xl px-5 mx-auto">
                <CompareHome />
            </div>
            <div className={`overflow-hidden relative mx-auto`}>
                <CategoriesTab />
                <div className={"hidden sm:block absolute bottom-0 right-0"}>
                    <CroppedOrangeDots />
                </div>
            </div>
            <div
                className="relative mx-auto py-12 "
                style={{
                    background:
                        "linear-gradient(0deg,#18336a 0,#18336a 50%,#fff 0,#fff)",
                }}
            >
                <GenericForm />
            </div>
            <TopVendors />
            <SupportedLanguages />
            {posts && posts.length ? (
                <FeaturedBlogPosts posts={posts} />
            ) : (
                <></>
            )}
        </>
    );
};

Page.getLayout = function getLayout(page: ReactElement) {
    return <DefaultLayout pageType="home">{page}</DefaultLayout>;
};

export const getStaticProps = async (ctx: GetStaticPropsContext) => {
    return await initialCall("HomePage", ctx);
};

export default Page;
