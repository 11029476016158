import styles from "./style.module.scss";

import DownArrow from "./icons/DownArrow";
import XIcon from "../modal/xIcon";
import { ChangeEvent, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { TranslationsObject } from "@genericTypes/sharedTypes";
export interface OptionType {
    id: number;
    label: string;
    slug: string;
    url?: string | undefined;
}
import { translate } from "@helpers/translationObjectFormatter";
import AnimateHeight from "../animateHeight";
export default function CustomSelect({
    options,
    onChange,
    placeHolder,
    addDefaultOption = false,
    className,
    defaultOption = null,
    withShadow = false,
    autoComplete = true,
    categorySlugPage = false,
}: {
    options: OptionType[];
    onChange: CallableFunction;
    placeHolder: string;
    addDefaultOption?: boolean;
    className?: string;
    defaultOption?: OptionType | null;
    withShadow?: boolean;
    autoComplete?: boolean;
    categorySlugPage?: boolean;
}) {
    const router = useRouter();
    const { data: translations } = useQuery<TranslationsObject>([
        "translations",
    ]);
    const [selectedItem, setSelectedItem] = useState(
        defaultOption ? defaultOption.label : "",
    );
    const [notFound, setNotFound] = useState(false);
    const [defaultOptions, setDefaultOptions] = useState<OptionType[]>(options);
    const [showSelect, setShowSelect] = useState(false);
    const sortOptionsAlphabetically = (a: OptionType, b: OptionType) => {
        return a.label?.localeCompare(b.label);
    };
    options?.sort(sortOptionsAlphabetically);

    const filterOptions = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        if (value.length === 0 && value === "") {
            setDefaultOptions(options);
        } else {
            const filteredDefaultOptions = options.filter((item) =>
                item.label.trim().toLowerCase().includes(value.toLowerCase()),
            );
            if (filteredDefaultOptions.length === 0) {
                setNotFound(true);
            } else {
                setNotFound(false);
                setDefaultOptions(filteredDefaultOptions);
                if (filteredDefaultOptions.length === 1 && autoComplete) {
                    setTimeout(() => {
                        onChange(filteredDefaultOptions[0]);
                        // setShowSelect(false);
                        setDefaultOptions(options);
                        setSelectedItem(filteredDefaultOptions[0].label);
                    }, 200);
                }
            }
        }
    };

    const handleOutsideClick = (e: MouseEvent) => {
        //@ts-ignore
        const { className } = e.target;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        if (
            typeof className !== "string" ||
            !className ||
            !className?.includes("customSelect")
        ) {
            setShowSelect(false);
        }
    };

    useEffect(() => {
        document.body.addEventListener("click", handleOutsideClick);

        return () => {
            document.body.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        setShowSelect(false);
    }, [selectedItem]);

    return (
        <div
            className={`${
                className ??
                "border rounded-[8px] absolute w-[250px] customSelect"
            } max-h-[250px] overflow-y-auto absolute bg-white z-50 border rounded-lg ${
                styles.container
            }`}
            tabIndex={0}
            style={
                withShadow
                    ? {
                          boxShadow: "0px 6px 11px rgba(0, 0, 0, 0.2)",
                      }
                    : {}
            }
        >
            <div
                className={`${
                    showSelect ? styles["borderRadiusStyle"] : ""
                } py-3 px-5 sticky top-0 customSelect bg-white ${
                    styles["borderStyle"]
                } flex items-baseline justify-between`}
                onClick={() => {
                    if (!showSelect) setShowSelect((prev) => !prev);
                }}
            >
                {showSelect ? (
                    <input
                        className={`${styles.input} z-[100000] customSelect`}
                        placeholder={translate(
                            translations,
                            "several_general_search",
                        )}
                        autoComplete={"off"}
                        id="optionsSearchInput"
                        autoFocus
                        onChange={(e) => filterOptions(e)}
                        onBlur={() => {
                            setTimeout(() => {
                                setDefaultOptions(options);
                                setNotFound(false);
                            }, 130);
                        }}
                    ></input>
                ) : (
                    <>
                        <span
                            className={` max-h-7 overflow-hidden customSelect ${
                                categorySlugPage ? "bottom-[7px] relative" : ""
                            } `}
                        >
                            {selectedItem ? selectedItem : placeHolder}
                        </span>
                        <span
                            className={`
                                ${categorySlugPage ? styles["iconStyle"] : ""}
                                ${styles["iconPointer"]}
                            `}
                            onClick={(e) => {
                                if (categorySlugPage) {
                                    e.stopPropagation();
                                    void router.push(`/blog`);
                                }
                            }}
                        >
                            {categorySlugPage ? <XIcon></XIcon> : <DownArrow />}
                        </span>
                    </>
                )}
            </div>

            {notFound ? (
                <div className={`pl-6 customSelect py-2`}>No results</div>
            ) : (
                <AnimateHeight
                    height={showSelect ? "auto" : 0}
                    duration={200}
                    easing={"ease-in-out"}
                >
                    {addDefaultOption && (
                        <div
                            key={-1}
                            className={`${styles["hoverStyle"]} customSelect pl-6  ps-6 py-2 cursor-pointer`}
                            onClick={() => {
                                if (selectedItem === "all") {
                                    setShowSelect(false);
                                    return;
                                }
                                setSelectedItem("all");
                                onChange({
                                    id: -1,
                                    label: "All",
                                    slug: "all",
                                });
                            }}
                        >
                            All
                        </div>
                    )}
                    {defaultOptions?.map((item) => (
                        <div
                            key={item.id}
                            className={`${styles["hoverStyle"]} customSelect pl-6  ps-6 py-2 cursor-pointer`}
                            onClick={() => {
                                if (selectedItem === item.label) {
                                    setShowSelect(false);
                                    return;
                                }
                                setSelectedItem(item.label);
                                onChange(item);
                            }}
                        >
                            {item.label}
                        </div>
                    ))}
                </AnimateHeight>
            )}
        </div>
    );
}
