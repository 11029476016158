import { atom } from "jotai";
// import { Offer } from "@components/category/bestOverAll/listItem";
// import { GQLOffer } from "@gql/types/gql";

// export const categories = atom([]);
export const selectedCategorySlug = atom<string>("");
export const showList = atom<boolean>(false);
export const showMultiList = atom<boolean>(false);
export const stepsActive = atom<boolean[]>([true, false, false]);
export const compareList = atom<string[]>([]);
