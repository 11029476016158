import React, { useMemo } from "react";
import styles from "./styles.module.scss";

export interface IPagesProps {
    pages: number[];
    currentPage: number;
    url: string;
}

const Pages = ({ pages, currentPage, url }: IPagesProps) => {
    const showPrevComponent = useMemo(() => {
        return currentPage >= 4 && pages.length > 4;
    }, [currentPage]);

    const showNextComponent = useMemo(() => {
        return currentPage !== pages.length && pages.length > 4;
    }, [currentPage]);

    return (
        <>
            {showPrevComponent && (
                <div className="flex items-center justify-center gap-2">
                    {currentPage >= 4 && (
                        <a
                            className="font-semibold flex items-center justify-center cursor-pointer text-black hover:text-[#2b93f0]"
                            href={`${url}/${1}`}
                        >
                            1
                        </a>
                    )}
                    <span className="font-bold mx-2">. . .</span>
                    <a
                        className="font-semibold flex items-center justify-center cursor-pointer text-black hover:text-[#2b93f0]"
                        href={`${url}/${currentPage - 1}`}
                    >
                        {currentPage - 1}
                    </a>
                </div>
            )}
            {currentPage < 4 || pages.length <= 4 ? (
                pages.slice(0, 4).map((item) => (
                    <a
                        key={item}
                        className={`font-semibold flex items-center justify-center cursor-pointer text-black hover:text-[#2b93f0] ${
                            currentPage === item ? styles["selected"] : ""
                        }`}
                        href={`${url}/${item}`}
                    >
                        {item}
                    </a>
                ))
            ) : (
                <a
                    className={`font-semibold flex items-center justify-center cursor-pointer text-black hover:text-[#2b93f0] ${styles["selected"]}`}
                    href={`${url}/${currentPage}`}
                >
                    {currentPage}
                </a>
            )}
            {showNextComponent && (
                <div className="flex items-center justify-center gap-2">
                    {currentPage >= 4 && (
                        <a
                            className="font-semibold flex items-center justify-center cursor-pointer text-black hover:text-[#2b93f0]"
                            href={`${url}/${currentPage + 1}`}
                        >
                            {currentPage + 1}
                        </a>
                    )}
                    {currentPage !== pages.length - 1 && (
                        <>
                            {pages.length - currentPage > 2 && (
                                <span className="font-bold mx-2">. . .</span>
                            )}
                            <a
                                href={`${url}/${pages.length}`}
                                className={`font-semibold flex items-center justify-center cursor-pointer text-black hover:text-[#2b93f0]`}
                            >
                                &nbsp;{pages.length}
                            </a>
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default Pages;
