import { iconsLookupsHelper } from "@helpers/iconsLookup";
import React, { Dispatch } from "react";
import styles from "./styles.module.scss";

const Tab = ({
    title,
    id,
    currentTabId,
    setCurrentTabId,
    slug,
}: {
    iconPath: string;
    title: string;
    id: number;
    currentTabId: number;
    setCurrentTabId: Dispatch<React.SetStateAction<number>>;
    slug: string;
}) => {
    const tabClickHandler = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        setCurrentTabId(parseInt(e.currentTarget.id));
    };

    return (
        <div
            className={`${
                currentTabId === id ? styles["selected"] : ""
            } flex flex-col items-center content-center gap-2 px-6 text-[#1693F0] cursor-pointer`}
            onClick={(e) => tabClickHandler(e)}
            id={id?.toString()}
        >
            {iconsLookupsHelper(slug)}
            <div className="text-[13px] sm:text-[16px] font-bold leading-[25px]">
                {title}
            </div>
            <div className={styles["arrow-up"]}></div>
        </div>
    );
};

export default Tab;
