import React, { ReactElement } from "react";
import Text from "@components/shared/text";
import Link from "@components/shared/linkNoPrefetch";
import { useRouter } from "next/router";
import languages from "@data/languages.json";
import Image from "next/legacy/image";
import styles from "./style.module.scss";
import { useMemo } from "react";
import { getUrl } from "@helpers/switchLanguage";
import { Languages, TranslationsObject } from "@genericTypes/sharedTypes";
import { useQuery } from "@tanstack/react-query";
import { translate } from "@helpers/translationObjectFormatter";

export default function SupportedLanguages(): ReactElement {
    const { pathname } = useRouter();
    const { data: translations } = useQuery<TranslationsObject>([
        "translations",
    ]);
    const langs: {
        [x: string]: {
            iso: string;
            flag: string;
            label: string;
            direction: string;
        };
    } = useMemo(() => {
        return languages.data;
    }, [languages.data]);
    return (
        <div className={`lightBg mb-20`}>
            <div className="max-w-6xl mx-auto text-center py-12 lg1048:py-24">
                <Text type="title" className="mb-12">
                    {translate(
                        translations,
                        "several_homePage_languagesSection_title",
                    )}
                </Text>

                <div className="max-w-4xl w-full mx-auto">
                    <div className={`flex ${styles.languages}`}>
                        {Object.values(langs)?.map((lang) => (
                            <Link
                                key={lang.iso}
                                href={getUrl(lang.iso as Languages, pathname)}
                                // locale={lang.iso}
                                className={styles.language}
                            >
                                <span
                                    className={`flex flex-row-reverse items-center justify-center`}
                                >
                                    <span
                                        className={`w-8 h-8 lg1048:w-11 lg1048:h-11 relative ms-5`}
                                    >
                                        <Image
                                            src={lang.flag}
                                            alt={lang.label}
                                            layout="fill"
                                        />
                                    </span>
                                    <span className="hidden lg1048:block">
                                        {lang?.label}
                                    </span>
                                </span>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
