import React, { ReactElement } from "react";

import BlogPosts from "@components/shared/blogPosts";
import { GQLPosts } from "@gql/types/gql";
import { useQuery } from "@tanstack/react-query";
import { TranslationsObject } from "@genericTypes/sharedTypes";
import { translate } from "@helpers/translationObjectFormatter";
export default function FeaturedBlogPosts({
    posts,
}: {
    posts: GQLPosts[];
}): ReactElement {
    // const { data } = useAllPostsQuery();
    const { data: translations } = useQuery<TranslationsObject>([
        "translations",
    ]);

    return (
        <BlogPosts
            posts={posts}
            title={translate(
                translations,
                "several_homePage_blogsSection_title",
            )}
            type="link"
        />
    );
}
