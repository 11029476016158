import React, { ReactElement, useMemo, useState } from "react";
import Text from "@components/shared/text";
import styles from "./style.module.scss";
import CustomSelect from "@components/shared/customSelect";
import { GQLCategories, GQLOffers, GQLUrls } from "@gql/types/gql";
import { useQuery } from "@tanstack/react-query";
import Vendor from "@components/topVenders/vendor";
import LinkButton from "@components/shared/form/linkButton";
import { Fade } from "react-awesome-reveal";
import { TranslationsObject } from "@genericTypes/sharedTypes";
import { translate } from "@helpers/translationObjectFormatter";
import { resolveURL } from "@lib/sharedUtils";
import { DomainOffer } from "@components/shared/accordion/category-item";

export default function TopVendors(): ReactElement {
    const { data } = useQuery<GQLCategories[], Error>({
        queryKey: ["categoriesAndOfferDropdown"],
    });
    const { data: translations } = useQuery<TranslationsObject>([
        "translations",
    ]);
    const { data: domainOffers } = useQuery<DomainOffer[]>([
        "homePageTopVendors",
    ]);

    function initCategorySelection(): GQLCategories {
        if (data) {
            const filteredData = data?.filter(
                (category) =>
                    category.parentId !== null &&
                    domainOffers?.find(
                        (offer) => offer.categoryId === category.id,
                    )?.offers.length,
            );
            const sortOptionsAlphabetically = (
                a: GQLCategories,
                b: GQLCategories,
            ) => {
                return a.label?.localeCompare(b.label as string);
            };

            //@ts-ignore
            filteredData?.sort(sortOptionsAlphabetically);

            return {
                id: filteredData[0]?.id ?? 0,
                label: filteredData[0]?.label ?? "",
                name: filteredData[0]?.name ?? "",
                slug: filteredData[0]?.slug ?? "",
            };
        }
        return {};
    }

    const [selectedSlug, setSelectedSlug] = useState<GQLCategories>(
        initCategorySelection(),
    );

    // all available categories
    const filteredCategories: GQLCategories[] | undefined = useMemo(() => {
        return data?.filter(
            (category) =>
                category.parentId !== null &&
                domainOffers?.find((offer) => offer.categoryId === category.id)
                    ?.offers.length,
        );
    }, [data]);

    function compare(a: GQLCategories, b: GQLCategories) {
        //@ts-ignore
        if (a.offers.length < b.offers.length) return 1;
        //@ts-ignore
        if (a.offers.length > b.offers.length) return -1;
        return 0;
    }
    const sortedFilteredCategories = filteredCategories?.sort(compare);

    const filterCurrentOffers: GQLOffers[] = useMemo(() => {
        return domainOffers
            ?.filter((offer) => offer.categoryId === selectedSlug?.id)
            .flatMap((item) => item.offers) as GQLOffers[];
    }, [selectedSlug]);

    const optionsCategories = useMemo(() => {
        return sortedFilteredCategories?.map((category) => {
            const option = {
                id: category.id,
                label: category.label,
                name: category.name,
                slug: category.slug,
            };
            return option;
        });
    }, [data]);

    return (
        <>
            {data && data.length > 0 && (
                <div
                    className={`flex flex-col items-center justify-center  px-12 md:pt-12 pb-12  max-w-7xl gap-6 ${styles.topVendorsContainer}`}
                >
                    <div
                        className={`${styles.vendorsBrief}  w-full flex flex-col items-center justify-center`}
                    >
                        <div className="text-center">
                            <Text className="text-2xl mb-2 font-extrabold text-[white]">
                                {translate(
                                    translations,
                                    "several_homePage_topVendros_title",
                                )}
                            </Text>
                            <Text className="text-base mb-4 font-base  text-[white]">
                                {translate(
                                    translations,
                                    "several_homePage_topVendors_subTitle",
                                )}
                            </Text>
                        </div>
                        <div
                            className={`w-full sm:w-[20rem] h-[3rem] relative`}
                        >
                            <CustomSelect
                                options={(
                                    optionsCategories as GQLCategories[]
                                )?.map((el) => ({
                                    id: el.id as number,
                                    label: el.label ?? el.name ?? "",
                                    slug: el.slug ?? "",
                                }))}
                                placeHolder={
                                    selectedSlug?.label ??
                                    (selectedSlug.name as string)
                                }
                                className={`w-[100%] border-[none]`}
                                onChange={(slug: GQLCategories) => {
                                    setSelectedSlug(slug);
                                }}
                                withShadow
                                //@ts-ignore
                                defaultOption={selectedSlug}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row max-w-6xl flex-wrap gap-2  justify-center mt-4">
                        {filterCurrentOffers?.map(
                            (item, index) =>
                                index < 6 && (
                                    <Fade
                                        key={item.slug}
                                        fraction={0}
                                        className="w-fit"
                                        triggerOnce
                                    >
                                        <Vendor
                                            imgPath={
                                                item.logo?.fullPath as string
                                            }
                                            slug={item.slug as string}
                                            key={item.slug}
                                            categorySlug={
                                                selectedSlug?.slug as string
                                            }
                                            url={resolveURL(
                                                item.url as GQLUrls,
                                            )}
                                        />
                                    </Fade>
                                ),
                        )}
                    </div>
                    <div className=" lg:w-[250px] w-[50%]">
                        <LinkButton
                            href={`${selectedSlug?.slug as string}`}
                            className="font-bold"
                        >
                            {translate(
                                translations,
                                "several_general_showMoreButton",
                            )}
                        </LinkButton>
                    </div>
                </div>
            )}
        </>
    );
}
