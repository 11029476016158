import styles from "./style.module.scss";

import { useMemo } from "react";

export default function OneElement({
    item,
    onCheckedOn,
    onCheckedOff,
    list,
    updateList,
}: {
    item: { id: number; label: string; slug: string };
    onCheckedOn: CallableFunction;
    onCheckedOff: CallableFunction;
    list: number[];
    updateList: CallableFunction;
}) {
    const checked = useMemo(() => !!list.includes(item.id), [list.length]);
    return (
        <div
            className={`${styles["hoverStyle"]} customSelect cursor-pointer relative `}
        >
            <div className={`${styles["checkBoxStyle"]} customSelect`}>
                <input
                    id={`input-${item.id}`}
                    className="customSelect"
                    onChange={(e) => {
                        if (e.target.checked) {
                            updateList([...list, item.id]);

                            onCheckedOn([...list, item.id]);
                        } else {
                            const listWithOutEl = list.filter(
                                (el) => el !== item.id,
                            );
                            updateList(listWithOutEl);
                            onCheckedOff(listWithOutEl);
                        }
                    }}
                    type="checkbox"
                    checked={checked}
                />

                <label
                    htmlFor={`input-${item.id}`}
                    className={`cursor-pointer  customSelect ${styles["colors"]}   pl-6`}
                >
                    <span
                        className={`${styles["box"]} customSelect`}
                        style={{
                            backgroundColor: checked ? "#1693f0" : undefined,
                        }}
                    >
                        <svg viewBox="0,0,50,50">
                            <path d="M5 30 L 20 45 L 45 5"></path>
                        </svg>
                    </span>
                    <span className="-ml-1 mr-2 customSelect">
                        {item.label}
                    </span>
                </label>
            </div>
        </div>
    );
}
