import { iconsLookupsHelper } from "@helpers/iconsLookup";
import React from "react";
import styles from "./styles.module.scss";
import Link from "@components/shared/linkNoPrefetch";

const Box = ({
    title,
    slug,
    url,
}: {
    iconPath: string;
    title: string;
    slug: string;
    url: string;
}) => {
    return (
        <Link
            href={`${url}`}
            id={slug}
            className={`flex flex-col sm:flex-row w-[9rem] sm:w-[15rem] min-h-[3rem] sm:min-h-[3.6rem] items-center content-center gap-4 py-2 px-3 m-2
         text-[#000000] border border-solid border-[#EDEDED] cursor-pointer rounded-[8px] transition ${styles.boxContainer}`}
        >
            <div className="w-[25px] h-[25px] flex items-center justify-center">
                {iconsLookupsHelper(slug)}
            </div>
            <div className="text-[13px] text-center ltr:sm:text-left rtl:sm:text-right  sm:text-[16px] font-medium leading-[21px]">
                {title}
            </div>
        </Link>
    );
};

export default Box;
