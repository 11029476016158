import Spinner from "@components/shared/spinner";
import React, { ReactElement, ReactNode } from "react";
import styles from "./styles.module.scss";
interface Props {
    className?: string;
    children: ReactNode;
    disabled?: boolean;
    onClick: () => void;
    icon?: ReactElement;

    loading?: boolean;
    style?: React.CSSProperties | undefined;
    type?: "button" | "submit" | "reset" | undefined;
}

export default function Button(props: Props): ReactElement {
    const {
        className = "",
        children,
        onClick,
        icon,

        loading,
        disabled,
        style,
        type = "button",
    } = props;

    return (
        <button
            type={type}
            style={style}
            className={`w-full  rounded-lg  flex justify-center items-center text-[white] bg-[#1693F0] cursor-pointer
                      disabled:bg-[#84c5f7] disabled:cursor-auto hover:bg-[#68beff]
                      ${className} ${styles.button}`}
            onClick={onClick}
            disabled={disabled}
        >
            {!loading ? (
                <span className={``}>
                    {icon && <span>{icon}</span>}
                    <span>{children}</span>
                </span>
            ) : (
                <Spinner size={25} color="#fff" success={false} />
            )}
        </button>
    );
}
