import { GQLCategories, GQLUrls } from "@gql/types/gql";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import Box from "./categoryBox";
import TabHeader from "./header";
import UpArrow from "../sideWidget/icons/UpArrow";
import { TranslationsObject } from "@genericTypes/sharedTypes";
import { translate } from "@helpers/translationObjectFormatter";
import { resolveURL } from "@lib/sharedUtils";
import AnimateHeight from "../animateHeight";

const CategoriesTab = () => {
    const { data } = useQuery<GQLCategories[], Error>({
        queryKey: ["categoriesAndOfferDropdown"],
    });
    const { data: translations } = useQuery<TranslationsObject>([
        "translations",
    ]);
    const [windowSize, setWindowSize] = useState<number[]>([]);

    const handleWindowResize = () => {
        setWindowSize([window.innerWidth, window.innerHeight]);
    };

    useEffect(() => {
        setWindowSize([window.innerWidth, window.innerHeight]);

        window.addEventListener("resize", handleWindowResize);

        () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    const [currentTabId, setCurrentTabId] = useState<number>(
        data?.filter(
            (item) =>
                item.parentId === null &&
                data.filter((category) => category.parentId === item.id)
                    .length > 0,
        )[0]?.id as number,
    );
    const [showMore, setShowMore] = useState(false);

    return (
        <div className="max-w-7xl px-5 mx-auto flex flex-col items-center content-center gap-9  overflow-hidden">
            {data && (
                <>
                    <TabHeader
                        data={data ?? null}
                        currentTabId={currentTabId}
                        setCurrentTabId={setCurrentTabId}
                    />
                    <AnimateHeight
                        duration={500}
                        height={
                            showMore
                                ? "auto"
                                : windowSize?.[0] >= 641
                                ? 155
                                : 122
                        }
                    >
                        <div className="flex flex-wrap justify-center">
                            {data
                                .filter(
                                    (item) => item.parentId === currentTabId,
                                )
                                .map((item) => (
                                    <Box
                                        iconPath={item.icon?.fullPath as string}
                                        title={
                                            (item.label as string) ??
                                            (item.name as string)
                                        }
                                        key={item.slug}
                                        slug={item.slug as string}
                                        url={resolveURL(item.url as GQLUrls)}
                                    />
                                ))}
                        </div>
                    </AnimateHeight>
                    {(data.filter((item) => item.parentId === currentTabId)
                        .length > 8 ||
                        windowSize?.[0] <= 641) && (
                        <button
                            className={`flex flex-row items-center justify-center gap-4 text-[14px] sm:text-[16px]`}
                            onClick={() => setShowMore((prev) => !prev)}
                        >
                            {showMore ? (
                                <>
                                    {translate(
                                        translations,
                                        "several_general_showLessButton",
                                    )}
                                </>
                            ) : (
                                <>
                                    {translate(
                                        translations,
                                        "several_general_showMoreButton",
                                    )}
                                </>
                            )}
                            <span
                                className={`${
                                    !showMore ? "rotate-180" : ""
                                } transition`}
                            >
                                <UpArrow />
                            </span>
                        </button>
                    )}
                </>
            )}
        </div>
    );
};

export default CategoriesTab;
