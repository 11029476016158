import React from "react";

function UpArrow() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="8"
            fill="none"
            viewBox="0 0 16 8"
        >
            <path
                fill="#58616D"
                fillRule="evenodd"
                d="M15.323 6.607L13.736 8 7.797 2.786 1.857 8 .272 6.607 7.797 0l7.526 6.607z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default UpArrow;
