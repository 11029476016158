import React, { useMemo } from "react";
import DownArrow from "../icons/downArrow";
import styles from "./styles.module.scss";
import Pages from "./Pages";

export interface IPaginationProps {
    total: number;
    offset: number;
    currentPage: number;
    url?: string | undefined;
}

const ARROW_SIZE = 12;

const Pagination = ({ total, offset, currentPage, url }: IPaginationProps) => {
    const numberOfPages = Math.ceil(total / offset);

    const pages = useMemo(() => {
        return numberOfPages && !isNaN(numberOfPages)
            ? Array.from(Array(numberOfPages).keys()).map((item) => (item += 1))
            : [];
    }, [numberOfPages]);

    const isNextDisabled = useMemo(() => {
        return currentPage === pages.length;
    }, [currentPage]);

    const isPrevDisabled = useMemo(() => {
        return currentPage === 1;
    }, [currentPage]);

    return (
        <div className="flex items-center justify-around">
            <a
                href={`${url as string}/${currentPage - 1}`}
                className={`${
                    isPrevDisabled ? styles["disabled"] : styles["arrow"]
                } rotate-90`}
            >
                <DownArrow width={ARROW_SIZE} />
            </a>
            <div className="flex gap-4">
                <Pages
                    pages={pages}
                    currentPage={currentPage}
                    url={url as string}
                />
            </div>
            <a
                href={`${url as string}/${currentPage + 1}`}
                className={`${
                    isNextDisabled ? styles["disabled"] : styles["arrow"]
                } -rotate-90`}
            >
                <DownArrow width={ARROW_SIZE} />
            </a>
        </div>
    );
};

export default Pagination;
