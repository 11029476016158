import React from "react";
import Text from "@components/shared/text";
import Tab from "./tabs";
import { GQLCategories } from "@gql/types/gql";
import style from "./style.module.scss";
import { TranslationsObject } from "@genericTypes/sharedTypes";
import { useQuery } from "@tanstack/react-query";
import { translate } from "@helpers/translationObjectFormatter";
const TabHeader = ({
    data,
    currentTabId,
    setCurrentTabId,
}: {
    data: GQLCategories[];
    currentTabId: number;
    setCurrentTabId: React.Dispatch<React.SetStateAction<number>>;
}) => {
    const { data: translations } = useQuery<TranslationsObject>([
        "translations",
    ]);

    return (
        <div
            className="flex max-w-[400px] sm:max-w-none flex-col gap-12 items-center"
            style={{ borderBottom: "1px solid #F0F0F0" }}
        >
            {data && (
                <>
                    <div className="px-4">
                        <Text type="title" className="text-center  mb-5">
                            {translate(
                                translations,
                                "several_homePage_categories_title",
                            )}
                        </Text>
                        <Text className="text-center  text-base lg1048:text-xl">
                            {translate(
                                translations,
                                "several_homePage_categories_subTitle",
                            )}
                        </Text>
                    </div>
                    <div className={`${style["shadowStyle"]}`}></div>
                    <div
                        className={` flex justify-center w-full gap-12 overflow-auto px-4 `}
                    >
                        {data
                            .filter(
                                (item) =>
                                    item.parentId === null &&
                                    data.filter(
                                        (category) =>
                                            category.parentId === item.id,
                                    ).length > 0,
                            )
                            .map((parent) => (
                                <Tab
                                    key={parent.id}
                                    iconPath={
                                        (parent.icon?.fullPath as string) ?? ""
                                    }
                                    title={
                                        (parent.label as string) ??
                                        (parent.name as string)
                                    }
                                    id={parent.id as number}
                                    currentTabId={currentTabId}
                                    setCurrentTabId={setCurrentTabId}
                                    slug={parent.slug as string}
                                />
                            ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default TabHeader;
